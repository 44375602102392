import React from "react";
import Seo from "../../../src/components/seo/index";
import CompanyLoginPopup from "./components/companyLoginPopup"

const Company = () => {
  return (
    <div>
      <Seo title="Green Heartfulness Run 2024" />
      <CompanyLoginPopup/>
    </div>
  );
};

export default Company;
